import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-39516ca6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "fl-row" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_enterprise_input = _resolveComponent("app-enterprise-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_tree_select = _resolveComponent("a-tree-select")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_app_operates = _resolveComponent("app-operates")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_app_table = _resolveComponent("app-table")!
  const _component_CreateBill = _resolveComponent("CreateBill")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createBlock(_component_a_card, _mergeProps(_ctx.$attrs, { class: "utils__pageContainer topUpRecordView" }), {
    title: _withCtx(() => [
      _createElementVNode("div", {
        innerHTML: _ctx.sldPageTitle('#1890ff', '对账单列表')
      }, null, 8, _hoisted_1)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_a_form, {
        class: "fl-clean filterForm",
        model: _ctx.filterParams,
        layout: 'inline'
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form_item, { label: "公司" }, {
            default: _withCtx(() => [
              _createVNode(_component_app_enterprise_input, {
                value: _ctx.filterParams.enterpriseId,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterParams.enterpriseId) = $event)),
                style: {"width":"200px"},
                export: "id"
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, { label: "部门" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_tree_select, {
                value: _ctx.filterParams.deptId,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filterParams.deptId) = $event)),
                "tree-data": _ctx.departmentList,
                style: {"width":"200px"},
                "show-search": "",
                "allow-clear": "",
                "tree-node-filter-prop": "title",
                "show-line": true,
                placeholder: "部门名称",
                "search-placeholder": "请选择"
              }, null, 8, ["value", "tree-data"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, { label: "账单状态" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select, {
                value: _ctx.filterParams.billStatus,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filterParams.billStatus) = $event)),
                style: {"width":"150px"},
                "allow-clear": "",
                placeholder: "全部"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select_option, { value: "" }, {
                    default: _withCtx(() => [
                      _createTextVNode("全部")
                    ]),
                    _: 1
                  }),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.billStatusEnum, (label, key) => {
                    return (_openBlock(), _createBlock(_component_a_select_option, {
                      key: key,
                      value: key
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(label), 1)
                      ]),
                      _: 2
                    }, 1032, ["value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, { label: "账单生成时间" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_range_picker, {
                value: _ctx.filterParams.timeRange,
                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.filterParams.timeRange) = $event)),
                format: "YYYY-MM-DD",
                "value-format": "YYYY-MM-DD HH:mm:ss",
                placeholder: ['起始时间', '结束时间'],
                style: {"width":"350px"}
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_button, {
                type: "primary",
                ghost: "",
                onClick: _ctx.onRefreshTable
              }, {
                default: _withCtx(() => [
                  _createTextVNode("查询")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model"]),
      _createVNode(_component_app_operates, { mg: "0 0 10px 0" }, {
        default: _withCtx(() => [
          _createVNode(_component_a_button, {
            type: "primary",
            onClick: _ctx.onShowModal
          }, {
            default: _withCtx(() => [
              _createTextVNode("生成对账单")
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      }),
      _createVNode(_component_app_table, {
        ref: "tableRef",
        size: "default",
        "row-key": "id",
        columns: _ctx.tableColumns,
        data: _ctx.loadTableData,
        "show-pagination": true,
        scroll: { x: 2500 }
      }, {
        billTime: _withCtx(({ record }) => [
          _createElementVNode("span", null, _toDisplayString(record.beginTime + '-' + record.endTime), 1)
        ]),
        billStatus: _withCtx(({ record }) => [
          (record.billStatus != 4)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.billStatusEnum[record.billStatus]), 1))
            : (_openBlock(), _createBlock(_component_a_tooltip, { key: 1 }, {
                title: _withCtx(() => [
                  _createTextVNode(_toDisplayString(record.refuseCause), 1)
                ]),
                default: _withCtx(() => [
                  _createTextVNode(" " + _toDisplayString(_ctx.billStatusEnum[record.billStatus]), 1)
                ]),
                _: 2
              }, 1024))
        ]),
        action: _withCtx(({ record }) => [
          _createElementVNode("div", _hoisted_3, [
            ([1,4].includes(record.billStatus))
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: "javascript:;",
                  class: "btn-red",
                  onClick: _withModifiers(($event: any) => (_ctx.onHandleTable(record,'delete')), ["prevent"])
                }, "删除", 8, _hoisted_4))
              : _createCommentVNode("", true),
            ([1].includes(record.billStatus))
              ? (_openBlock(), _createElementBlock("a", {
                  key: 1,
                  href: "javascript:;",
                  onClick: _withModifiers(($event: any) => (_ctx.onHandleTable(record,'start')), ["prevent"])
                }, "发起对账", 8, _hoisted_5))
              : _createCommentVNode("", true),
            ([2].includes(record.billStatus))
              ? (_openBlock(), _createElementBlock("a", {
                  key: 2,
                  href: "javascript:;",
                  onClick: _withModifiers(($event: any) => (_ctx.onHandleTable(record,'revoke')), ["prevent"])
                }, "撤销对账", 8, _hoisted_6))
              : _createCommentVNode("", true),
            _createElementVNode("a", {
              href: "javascript:;",
              onClick: _withModifiers(($event: any) => (_ctx.gotoDetail(record)), ["prevent"])
            }, "账单明细", 8, _hoisted_7),
            (record.downUrl)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 3,
                  href: "javascript:;",
                  onClick: _withModifiers(($event: any) => (_ctx.onDownload(record)), ["prevent"])
                }, "下载账单", 8, _hoisted_8))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["columns", "data"]),
      (_ctx.showModal)
        ? (_openBlock(), _createBlock(_component_CreateBill, {
            key: 0,
            onClose: _ctx.closeModal
          }, null, 8, ["onClose"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 16))
}