
import { defineComponent, computed, reactive, ref, getCurrentInstance, onMounted } from 'vue';
import { noEmptyProp, not, dispatchDownload } from '@/utils/illuminate';
import { message } from 'ant-design-vue';
import moment, { Moment, unitOfTime } from 'moment';

import { useStore } from 'vuex';
import { createApiUrl } from '@/utils/utils';
import type { TableColumn, TableOptions } from '@/components/Table';
import { request } from '@/utils/request';
import { formatDate } from '@/core/filters';
import { useUtils } from '@/core/utils';

const accountTypeMap = {
  staff_direct_charge: '员工直充账户',
  staff_gasoline: '汽油账户',
  staff_diesel_oil: '员工柴油户',
  staff_natural_gas: '员工天然气户',
  staff_electricity: '员工电账户',

  direct_charge: '企业账户',
  energy_gasoline: '汽油账户',
  energy_diesel_oil: '能源柴油户',
  energy_natural_gas: '能源天然气户',
  energy_electricity: '能源电账户',
};

export default defineComponent({
  name: 'WaterListView',
  setup(props, ctx) {
    const instance = getCurrentInstance()?.proxy;
    const store = useStore();
    const utils = useUtils();
    const filterParams = reactive<{
      timeRange: Moment[];
      state: string;
      timeState: string;
      queryEnterpriseId: number
    }>({
      timeRange: [],
      state: '',
      timeState: 'TIME',
      queryEnterpriseId: store.state.User.enterprise.enterpriseId,
    });
    const groupEnterpriseFlag = store.state.User.enterprise?.groupEnterpriseFlag;

    const loadTableData = async (parameter: any) => {
      try {
        const params: any = { ...parameter, ...noEmptyProp(not(filterParams, ['timeRange'])) };
        if (filterParams.timeRange.length) {
          params.startTime = moment(filterParams.timeRange[0].format('YYYY-MM-DD 00:00:00')).valueOf();
          params.endTime = moment(filterParams.timeRange[1].format('YYYY-MM-DD 23:59:59')).valueOf();
        }

        const res = await request.get(createApiUrl('/newlinkSass/finance/monthly-detail-bills-list'), {
          params,
        });

        return res.data;
      } catch (e: Error & any) {
        message.error(e.message);
      }
    };

    const tableRef = ref<any>(null);
    const onRefreshTable = () => {
      tableRef.value.refresh(true);
    };
    const attachTimeRange = (range: unitOfTime.Base) => {
      filterParams.timeRange = [moment().startOf(range), moment().endOf(range)];
      onRefreshTable();
    };

    const isDayOn = computed(() => filterParams.timeRange.length
      && filterParams.timeRange[0].valueOf() === moment().startOf('day').valueOf()
      && filterParams.timeRange[1].valueOf() === moment().endOf('day').valueOf());

    const isWeekOn = computed(() => filterParams.timeRange.length
      && filterParams.timeRange[0].valueOf() === moment().startOf('week').valueOf()
      && filterParams.timeRange[1].valueOf() === moment().endOf('week').valueOf());

    const isMonthOn = computed(() => filterParams.timeRange.length
      && filterParams.timeRange[0].valueOf() === moment().startOf('month').valueOf()
      && filterParams.timeRange[1].valueOf() === moment().endOf('month').valueOf());

    const tableColumns: TableColumn[] = [
      { title: '交易时间', dataIndex: 'time', customRender: ({ text }) => formatDate(text) },
      { title: '交易类型', dataIndex: 'stateName' },
      { title: '资金方向', dataIndex: 'direction' },
      { title: '交易金额', dataIndex: 'amount' },
      { title: '账户可用余额', dataIndex: 'afterAmount' },
      { title: '交易流水号', dataIndex: 'requestId' },
    ];

    // = ---------------------------- = 导出数据 = ---------------------------- =
    const confirmVisible = ref(false);
    const exportParams = reactive<{
      timeRange: Moment[];
    }>({
      timeRange: [],
    });

    const onExport = () => {
      Object.assign(exportParams, {
        timeRange: [],
      });
      confirmVisible.value = true;
    };

    let firstSelectTime: Moment | null;
    const isDateDisable = (currentDate: Moment) => {
      if (moment().valueOf() - currentDate.valueOf() < 0) return true;
    };
    const onOpenChange = (value: boolean) => {
      if (!value) firstSelectTime = null;
    };

    const onCalendarChange = (dateRange: [Moment, Moment]) => {
      // eslint-disable-next-line prefer-destructuring
      firstSelectTime = dateRange[0];
    };

    const exporting = ref(false);
    const onConfirmExport = async () => {
      const params: Record<string, any> = {};
      if (!exportParams.timeRange.length) {
        message.warning('请选择时间范围在导出');
        return;
      }
      if (exportParams.timeRange.length) {
        params.startDate = exportParams.timeRange[0].format('YYYY-MM-DD');
        params.endDate = exportParams.timeRange[1].format('YYYY-MM-DD');
      }
      let res: any;
      try {
        res = await request.get(createApiUrl('/newlinkSass/excel/down/financeDetail'), { responseType: 'blob', params: { ...params } });
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      const blob = new Blob([res.data], {
        type: 'application/octet-stream;charset=UTF-8',
        endings: 'transparent',
      });

      const url = URL.createObjectURL(blob);
      const dataTime = moment().format('YYYYMMDDhhmmss');
      dispatchDownload(`账户流水记录${dataTime}.xlsx`, url);
      // 释放之前创建的URL对象
      window.URL.revokeObjectURL(url);
      confirmVisible.value = false;
    };

    onMounted(() => {
      instance?.$sensors.track('es_saas_bill_list_page_view', { page_name: '账单_账户流水_浏览' });
    });

    return {
      tableData: [],
      pagination: { total: 100 },

      EXCHANGE_TYPE_MAP: utils.EXCHANGE_TYPE_MAP,
      filterParams,
      tableColumns,
      attachTimeRange,
      isDayOn,
      isWeekOn,
      isMonthOn,

      tableRef,
      onRefreshTable,
      loadTableData,

      isDateDisable,
      onOpenChange,
      onCalendarChange,

      onExport,

      confirmVisible,
      exporting,
      exportParams,
      onConfirmExport,
      groupEnterpriseFlag,
    };
  },
});
