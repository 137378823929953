
import { request } from '@/utils/request';
import { defineComponent, reactive, ref, onMounted, watch } from 'vue';
import { createApiUrl } from '@/utils/utils';
import { message, Modal } from 'ant-design-vue';
import moment, { Moment } from 'moment';
import { EnterpriseItem, useSubsidiary } from '@/views/parts/subsidiary';
import { RuleObject } from 'ant-design-vue/es/form/interface';
import { useState } from '@/store';
import { string } from 'vue-types';
import ResultModal from './ResultModal.vue';

type userInfoType = {
  userName: string
  userCode: string
  phone: string
}
type formType = {
  billDimension: string,
  timeRange: Moment[]
}
interface TreeDataItem {
  key: string;
  title: string;
  disabled?: boolean;
  children?: TreeDataItem[];
  [prop:string]:any
}
type deptItemType = {
  departmentId: string | number, 
  departmentName:string, 
  enterpriseId:string | number, 
  enterpriseName: string
}

export default defineComponent({
  components: {
    ResultModal,
  },
  props: {
    rowInfo: { type: Object, default: () => ({}) },
  },
  emits: ['close'],
  setup(props, ctx) {
    const state = useState();
    const visible = ref<boolean>(true);
    const loading = ref<boolean>(false);
    const handleCancel = () => {
      ctx.emit('close');
    };

    const formRef = ref();
    const formState = reactive<formType>({
      billDimension: 'ENTERPRISE',
      timeRange: [],
    });

    /*
     *** 半穿梭选择框 公司 ｜ 部门 ***
    */
    
    /** 公司 */
    const checkedEnterpriseIds = ref<number[]>([]);
    const enterpriseTree = ref<(EnterpriseItem & any)[]>([]);
    let enterpriseList: (EnterpriseItem & any)[] = [];
    const subsidiaryState = useSubsidiary();
    /** 部门 */
    const checkedDepartmentIds = ref<number[]>([]);
    const departmentTree = ref<TreeDataItem[]>([]);
    let departmentList: deptItemType[] = [];

    /** 校验 */
    const checkEnterOrDept = async (rule: RuleObject, value: number) => {
      const { checked } = checkedEnterpriseIds.value as Record<string, any>;
      const deptSelected = checkedDepartmentIds.value;
      if ((Array.isArray(checked) && checked.length) || (Array.isArray(deptSelected) && deptSelected.length)) {
        return Promise.resolve();
      } 
      const msgTitle = formState.billDimension === 'ENTERPRISE' ? '公司' : '部门';
      return Promise.reject(`请选择${msgTitle}`);
    };
    const rules = {
      timeRange: [{ type: 'array', required: true, message: '请选择日期', trigger: 'change' }],
      billDimension: [{ required: true, message: '请选择账单类型', trigger: 'change' }],
      enterpriseOrDept: [
        { required: true, validator: checkEnterOrDept, trigger: 'blur' },
      ],
    };
    const changeBillDimension = (e:Event) => {
      const { value } = e.target as HTMLSelectElement;
      if (value === 'ENTERPRISE') {
        checkedDepartmentIds.value = [];
      } else {
        checkedEnterpriseIds.value = [];
      }
    };

    watch([() => checkedEnterpriseIds.value, () => checkedDepartmentIds.value], ([newEnter, newDept]) => {
      if (newEnter || newDept) {
        formRef.value.validateFields('enterpriseOrDept');
      }
    });

    /** 部门转换 */
    const transformDept = (data:Record<string, any>[]) => {
      const tempList:deptItemType[] = [];
      const tempTree = data.map(enterpriseObj => {
        const { enterpriseId, enterpriseName, departmentList } = enterpriseObj;
        return {
          key: enterpriseId,
          title: enterpriseName,
          children: (departmentList || []).map((deptObj:Record<string, any>) => {
            const { id, departmentName, enterpriseId, enterpriseName } = deptObj;
            tempList.push({ departmentId: id, departmentName, enterpriseId, enterpriseName });
            return {
              key: `dept_${id}`,
              title: departmentName,
              isisLeaf: true, // 无子节点
              enterpriseId,
              enterpriseName,
            };
          }),
        };
      });
      return { tempTree, tempList };
    };
    const getDepartmentList = () => {
      const enterpriseIdList = enterpriseList.map(item => item.id);
      request.post(createApiUrl('/newlinkSass/enterprise/batch-find-enterprise-department'),
        { enterpriseIdList }, { noEnterpriseId: true })
        .then(({ data }) => {
          const { tempTree, tempList } = transformDept(data);
          departmentTree.value = tempTree;
          departmentList = tempList;
        });
    };


    const getEnterpriseOrDeptList = (billDimension:string) => {
      if (billDimension === 'ENTERPRISE') {
        const myCheckIds = (checkedEnterpriseIds.value as Record<string, any>).checked;
        return enterpriseList.filter(obj => myCheckIds.includes(obj.id)).map(obj => ({ enterpriseId: obj.enterpriseId, enterpriseName: obj.enterpriseName }));
      } 
      const tempDeptIds:(number | string)[] = [];
      checkedDepartmentIds.value.forEach((key:string|number) => {
        if (typeof key === 'string' && key.includes('dept_')) {
          tempDeptIds.push(+key.split('_')[1]);
        }
      });
      return departmentList.filter(deptObj => tempDeptIds.includes(deptObj.departmentId));
    };
    const resultInfo = ref({});
    const isShowResult = ref<boolean>(false);
    const onSubmit = () => {
      loading.value = true;
      const { timeRange, billDimension } = formState;
      const data = {
        beginTime: moment(timeRange[0]).format('YYYY-MM-DD 00:00:00'), 
        endTime: moment(timeRange[1]).format('YYYY-MM-DD 23:59:59'),
        billDimension,
        enterpriseOrDeptList: getEnterpriseOrDeptList(billDimension),
      };
      request.post(createApiUrl('/newlinkSass/accountStatement/create'), data)
        .then(({ code, data }) => {
          if (code !== 200) return;
          const { successTotal, failureTotal, failureResult } = data;
          if (failureTotal === 0) {
            message.success('账单生成中');
            ctx.emit('close', true);
            return;
          }
          resultInfo.value = { successTotal, failureTotal, failureResult };
          visible.value = false;
          isShowResult.value = true;
        }).catch(e => {
          message.error(e.message);
        }).finally(() => {
          loading.value = false;
        });
    };
    const closeResultModal = () => {
      isShowResult.value = false;
      ctx.emit('close', true);
      visible.value = true;
    };
    const handleOk = () => {
      formRef.value.validate()
        .then(() => {
          onSubmit();
        });
    };

    
    onMounted(async () => {
      const data = await subsidiaryState.loadEnterpriseTreeData();
      enterpriseList = data.list;
      enterpriseTree.value = data.tree;
      departmentTree.value = JSON.parse(JSON.stringify(data.list));
      formState.billDimension = 'ENTERPRISE'; // 此处设置初始值，延缓打开企业树
      getDepartmentList();
    });
    
    return {
      visible,
      loading,
      formRef,
      formState,
      rules,
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
      handleCancel,
      handleOk,

      changeBillDimension,
      checkedEnterpriseIds,
      enterpriseTree,

      checkedDepartmentIds,
      departmentTree,

      isShowResult,
      resultInfo,
      closeResultModal,
    };
  },
});
