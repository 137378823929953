
import { defineComponent, reactive, ref, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import { message } from 'ant-design-vue';
import { webSocketApiUrl } from '@/utils/utils';

type PayInfo = {
  payType: number,
  paySn: string,
  params: string,
  qrCode: string,
  amount: number,
};

export default defineComponent({
  emits: ['payComplete'],
  setup(props, ctx) {
    const store = useStore();
    const visible = ref(false);
    const payInfo = reactive<PayInfo>({
      payType: 1,
      paySn: '',
      params: '',
      qrCode: '',
      amount: 0,
    });
    const enterpriseName = ref(store.state.User.enterprise.enterpriseName);

    let socket: WebSocket | null;

    const handleClose = () => {
      socket && socket.close();
      socket = null;
      visible.value = false;
    };

    const socketUrl = `${webSocketApiUrl()}?appType=1&enterpriseId=${store.state.User.enterprise.enterpriseId}&userCode=${store.state.User.userInfo.account}`;

    const socketOpen = () => {
      console.log(store.state.User.userInfo.account);
    };

    const socketError = (e: any) => {
      console.log('Socket Error', e);
    };

    const socketMessage = (msg: any) => {
      const socketResult = JSON.parse(msg.data);
      if (socketResult!.result) {
        handleClose();
        message.success('支付完成');
        ctx.emit('payComplete', socketResult);
      } else {
        handleClose();
      }
    };

    const socketConnect = () => {
      socket = new WebSocket(socketUrl);
      socket.onopen = socketOpen;
      socket.onerror = socketError;
      socket.onmessage = socketMessage;
      socket.onclose = () => {
        socket = null;
      };
    };

    const showDialog = (obj: PayInfo) => {
      Object.assign(payInfo, obj);
      visible.value = true;
      socketConnect();
    };

    onBeforeUnmount(() => {
      handleClose();
    });

    return {
      visible,
      payInfo,
      enterpriseName,

      showDialog,
      handleClose,
    };
  },
});
