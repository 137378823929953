
import { defineComponent, reactive, ref, watchEffect } from 'vue';
import moment, { Moment } from 'moment';
import { noEmptyProp, not, dispatchDownload, settleTree } from '@/utils/illuminate';
import type { TableColumn } from '@/components/Table';
import { request } from '@/utils/request';
import { createApiUrl, sldPageTitle } from '@/utils/utils';
import { useRouter } from 'vue-router';
import { message, Modal } from 'ant-design-vue';
import { useStore } from 'vuex';
import { billStatusEnum } from './dict';
import CreateBill from './parts/CreateBill.vue';

export default defineComponent({
  name: 'FinanceBill',
  components: {
    CreateBill,
  },
  setup(prop, ctx) {
    const store = useStore();
    const router = useRouter();
    const filterParams = reactive<{
      enterpriseId: string | number;
      deptId: string | number | undefined;
      billStatus: string | number;
      timeRange: Moment[];
    }>({
      enterpriseId: store.state.User.enterprise?.enterpriseId,
      deptId: '',
      billStatus: '',
      timeRange: [],
    });
    const tableRef = ref<any>(null);
    const dataSource = ref([]);

    const tableColumns: TableColumn[] = [
      { title: '账单编号', dataIndex: 'showBillNo', width: '210px' },
      { title: '账单时间', dataIndex: 'billTime', width: '280px', slots: { customRender: 'billTime' } },
      { title: '部门名称', dataIndex: 'deptName', width: '180px' },
      { title: '公司名称', dataIndex: 'enterpriseName', width: '250px' },
      { title: '统一社会信用代码', dataIndex: 'socialCreditCode', width: '200px' },
      { title: '账单总金额', dataIndex: 'billTotalAmount' },
      { title: '消费总金额', dataIndex: 'orderTotalAmount' },
      { title: '退款总金额', dataIndex: 'orderTotalRefundAmount' },
      { title: '账单生成时间', dataIndex: 'createTime', width: '180px' },
      { title: '账单状态', dataIndex: 'billStatus', slots: { customRender: 'billStatus' } },
      { title: '已还款金额', dataIndex: 'repaymentAmount' },
      { title: '未还款金额', dataIndex: 'notRepaymentAmount' },
      { title: '操作', dataIndex: 'action', width: '270px', align: 'center', slots: { customRender: 'action' }, fixed: 'right' },
    ];

    const loadTableData = async (parameter: any) => {
      const params = {
        ...not(noEmptyProp(filterParams), ['timeRange']),
        ...parameter,
      };

      if (filterParams.timeRange.length) {
        const [start, end] = filterParams.timeRange;
        params.billCreateStartTime = moment(start).format('YYYY-MM-DD 00:00:00');
        params.billCreateEndTime = moment(end).format('YYYY-MM-DD 23:59:59');
      }
      try {
        const { data } = await request.post(createApiUrl('/newlinkSass/accountStatement/list'), { ...params }, { noEnterpriseId: true });
        return data;
      } catch (e:any) {
        message.error(e.message);
      }
    };

    const onRefreshTable = () => {
      tableRef.value.refresh();
    };

    // 删除
    const confirmMsg = (title:string, content:string, row:Record<string, any>, api:string, data:Record<string, any>) => {
      Modal.confirm({
        title,
        content,
        okText: '确定',
        cancelText: '取消',
        onOk() {
          request.post(createApiUrl(`/newlinkSass${api}`), { ...data }, { noEnterpriseId: true }).then(() => {
            message.success(`已${title}`);
            onRefreshTable();
          }).catch((e: any) => {
            message.error(e.message);
          });
        },
      });
    };
    const onHandleTable = (row:Record<string, any>, flag:string) => {
      if (flag === 'delete') {
        confirmMsg('删除对账单', '删除后可重新生成对账单', row, '/accountStatement/delete', { id: row.id, status: 'DELETE' });
      } else if (flag === 'revoke') {
        confirmMsg('撤回对账', '确定后将撤回对账单，可重新修改账单', row, '/accountStatement/auditOrVerify', { id: row.id, status: 'REVOKE' });
      } else {
        confirmMsg('发起对账', '请确保账单无误后再发起对账，发起后我们将在1个工作日内与贵司对账', row, '/accountStatement/auditOrVerify', { id: row.id, status: 'DOING' });
      }
    };
    // 下载
    const onDownload = async (row:Record<string, any>) => {
      const { downUrl } = row;
      if (!downUrl) return;
      const index = downUrl.lastIndexOf('/');
      const xlsxName = decodeURIComponent(downUrl.substring(index + 1));
      dispatchDownload(xlsxName, row.downUrl);
    };
    // 详情
    const gotoDetail = (row: Record<string, any>) => {
      router.push({ name: 'finance.bill-detail', query: { billNo: row.billNo } });
    };
    // 生成账单
    const showModal = ref<boolean>(false);
    const onShowModal = () => {
      showModal.value = true;
    };
    const closeModal = (isRefresh = false) => {
      showModal.value = false;
      if (isRefresh) {
        onRefreshTable();
      }
    };
    /* = -------------------------------------------------- = 加载搜索用的部门树 = -------------------------------------------------- = */
    const departmentList = ref<any[]>([]);
    const loadEnterpriseDepartment = async (val:any) => {
      filterParams.deptId = undefined; // 清空部门
      // 公司查询全部 部门为空
      if (val === '' || val === undefined) {
        departmentList.value = [];
        return;
      }
      let res: any;
      try {
        res = await request.get(createApiUrl('/newlinkSass/enterprise/find-enterprise-department'), { params: { enterpriseId: val }, noEnterpriseId: true });
      } catch (e: Error & any) {
        return message.error(e.message);
      }
      departmentList.value = settleTree(res.data.departmentList,
        { key: 'id', title: 'departmentName', value: 'id' },
        'departmentList');
    };

    watchEffect(() => {
      loadEnterpriseDepartment(filterParams.enterpriseId);
    });

    return {
      billStatusEnum,
      filterParams,
      tableRef,
      tableColumns,
      loadTableData,
      onRefreshTable,
      dataSource,
      onHandleTable,
      gotoDetail,
      onDownload,
      
      showModal,
      onShowModal,
      closeModal,

      departmentList,
      loadEnterpriseDepartment,
      sldPageTitle,
    };
  },
});
