
import { message } from 'ant-design-vue';
import { defineComponent, onMounted, reactive, ref, watch } from 'vue';
import { ReloadOutlined } from '@ant-design/icons-vue';
import moment, { Moment } from 'moment';
import { not, formatWithIntl } from '@/utils/illuminate';
import { useStore } from 'vuex';
import { Icon } from '@/components/Icon';
import { request } from '@/utils/request';
import { createApiUrl } from '@/utils/utils';
import { formatDate } from '@/core/filters';
import type { TableColumn } from '@/components/Table';

export default defineComponent({
  name: 'StatementListView',
  components: {
    [Icon.name]: Icon,
    ReloadOutlined,
  },
  setup(props, ctx) {
    const store = useStore();

    // ==================== 月结账单 ====================
    const tableColumns: TableColumn[] = [
      { title: '账单月份', dataIndex: 'billMonth' },
      { title: '公司', dataIndex: 'enterpriseName' },
      { title: '期初余额', dataIndex: 'beforeBalance' },
      { title: '期末余额', dataIndex: 'afterBalance' },
      { title: '充值金额', dataIndex: 'firstInAmount' },
      { title: '内部资金划拨', dataIndex: 'insideAmount', customRender: ({ text }) => formatWithIntl(text) },
      { title: '消费金额', dataIndex: 'orderAmount' },
      { title: '退款金额', dataIndex: 'orderRefundAmount' },
      { title: '操作', dataIndex: 'action', width: '100px', slots: { customRender: 'action' }, fixed: 'right' },
    ];

    const loadTableData = async (parameter: any) => new Promise<void>((resolve, reject) => {
      request.get(createApiUrl('/newlinkSass/finance/monthly-group-bills'), { params: parameter })
        .then((res: any) => {
          resolve(res.data);
        }).catch((e: any) => {
          message.error(e.message);
        });
    });

    const tablesColumns: TableColumn[] = [
      { title: '账单日期', dataIndex: 'startTime', slots: { customRender: 'startTime' } },
      { title: '公司', dataIndex: 'enterpriseName' },
      { title: '生成时间', dataIndex: 'createTime', customRender: ({ text }) => formatDate(text) },
      { title: '操作', dataIndex: 'action', width: '100px', slots: { customRender: 'action' }, fixed: 'right' },
    ];

    const filterParams = reactive<{
      timeRange: Moment[];
      enterpriseId: number;
    }>({
      timeRange: [],
      enterpriseId: store.state.User.enterprise?.enterpriseId,
    });

    const disabledEndDate = (endValue: any) => {
      const startValue: any = filterParams.timeRange[0];
      if (!endValue || !startValue) return false;
      return endValue > moment(startValue.valueOf()).add(92, 'd') || startValue.valueOf() > endValue.valueOf();
    };

    const disabledStartDate = (startValue: any) => {
      const endValue: any = filterParams.timeRange[1];
      if (!endValue || !startValue) return false;
      return startValue < moment(endValue.valueOf()).subtract(92, 'd') || startValue.valueOf() > endValue.valueOf();
    };

    const loadTablesData = async (parameter: any) => new Promise<void>((resolve, reject) => {
      const params: Record<string, any> = {
        ...parameter, ...not(filterParams, ['timeRange']),
      };

      request.post(createApiUrl('/newlinkSass/enterprise/export/bill/list'), params)
        .then((res: any) => {
          resolve(res.data);
        }).catch((e: any) => {
          message.error(e.message);
        });
    });

    const tableRef = ref<any>(null);
    const onRefreshTable = () => {
      tableRef.value.refresh(true);
    };

    // 生成账单
    const createOrder = async () => {
      if (!filterParams.timeRange[0] && !filterParams.timeRange[1]) return message.error('请选择时间！');
      if (!filterParams.timeRange[0]) return message.error('请选择开始时间！');
      if (!filterParams.timeRange[1]) return message.error('请选择结束时间！');

      try {
        await request.post(createApiUrl('/newlinkSass/enterprise/export/bill/create'), {
          enterpriseId: filterParams.enterpriseId,
          startTime: moment(filterParams.timeRange[0].format('YYYY-MM-DD 00:00:00')).valueOf(),
          endTime: moment(filterParams.timeRange[1].format('YYYY-MM-DD 23:59:59')).valueOf(),
        });
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      onRefreshTable();
    };

    return {
      tableColumns,
      loadTableData,

      tablesColumns,
      filterParams,
      loadTablesData,
      tableRef,
      onRefreshTable,
      createOrder,

      disabledEndDate,
      disabledStartDate,
    };
  },
});
