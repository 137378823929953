import { ref } from 'vue';
import { message } from 'ant-design-vue';
import { assignWithDefault } from '@/utils/illuminate';

import { request } from '@/utils/request';
import { createApiUrl } from '@/utils/utils';

export function useFinance() {
  const defaultFinanceDetail = {
    availableAmount: 0,
    childEnterpriseBalance: 0,
    frozenAmount: 0,
    preferentialAmount: 0,
    totalBalance: 0,
    invoice: 0,
  };

  const financeDetail = ref({
    ...defaultFinanceDetail,
  });

  const loadFinanceDetail = async () => {
    let res: any;
    try {
      res = await request.get(createApiUrl('/newlinkSass/finance/find-finance-detail'));
    } catch (e: Error & any) {
      return message.error(e.message);
    }

    assignWithDefault(financeDetail.value, res.data, defaultFinanceDetail);
  };

  return {
    financeDetail,
    loadFinanceDetail,
  };
}
