
import { defineComponent, nextTick, onMounted, reactive, ref, watch } from 'vue';
import { message } from 'ant-design-vue';

import { useStore } from 'vuex';
import { formatWithIntl } from '@/utils/illuminate';
import { request } from '@/utils/request';
import { createApiUrl, toLocalUnit } from '@/utils/utils';
import { TableColumn } from '@/components/Table';
import { useState } from '@/store';

/**
 * 转账记录表
 */
export default defineComponent({
  name: 'TransformLog',
  setup(props, ctx) {
    const store = useStore();
    const visible = ref(false);
    const loading = ref(false);

    const filterParams = reactive({
      enterpriseId: undefined,
    });

    const tableColumns: TableColumn[] = [
      { title: '公司名称', dataIndex: 'enterpriseName', width: '50%' },
      { title: '账户余额(元)', dataIndex: 'enterpriseBalance', width: '50%', customRender: ({ text }) => formatWithIntl(toLocalUnit(+text)) },
    ];

    // = ---------------------------- = 加载数据方法 = ---------------------------- =
    const loadTableData = (parameter: any) => new Promise<any>((resolve, reject) => {
      const params: any = {};
      if (filterParams.enterpriseId) params.enterpriseIdList = [filterParams.enterpriseId];
      request.post(createApiUrl('/newlinkSass/finance/account/children/balance'), { ...parameter, ...params })
        .then((res: any) => {
          resolve(res.data);
        }).catch((e: any) => {
          message.error(e.message);
        });
    });

    const tableRef = ref<any>(null);
    const onRefreshTable = () => {
      tableRef.value.refresh(true);
    };

    watch(() => filterParams.enterpriseId, onRefreshTable);

    const view = () => {
      visible.value = true;
      // 二次显示刷新数据
      tableRef.value && nextTick(() => onRefreshTable());
    };

    return {
      filterParams,
      loading,
      visible,
      view,

      tableRef,
      tableColumns,
      loadTableData,
    };
  },
});
