
import { defineComponent, nextTick, onMounted, ref, reactive } from 'vue';
import { message } from 'ant-design-vue';

import { formatWithIntl, dispatchDownload } from '@/utils/illuminate';
import { request } from '@/utils/request';
import { createApiUrl, toLocalUnit } from '@/utils/utils';
import { TableColumn } from '@/components/Table';
import { formatDate } from '@/core/filters';
import moment, { Moment } from 'moment';


/**
 * 转账记录表
 */
export default defineComponent({
  name: 'TransformLog',
  setup(props, ctx) {
    const visible = ref(false);
    const loading = ref(false);
    const exportParams = reactive<{
      transactionTime: Moment[];
    }>({
      transactionTime: [],
    });

    const tableColumns: TableColumn[] = [
      { title: '时间', dataIndex: 'transactionTime', customRender: ({ text }) => formatDate(text) },
      { title: '公司', dataIndex: 'enterpriseName' },
      { title: '类型', dataIndex: 'transactionName' },
      { title: '金额', dataIndex: 'transactionAmount', customRender: ({ text }) => formatWithIntl(toLocalUnit(text)) },
      { title: '操作人', dataIndex: 'operationUserName', customRender: ({ record }) => `${record.operationUserName} - ${record.operationPhone}` },
    ];

    // = ---------------------------- = 加载数据方法 = ---------------------------- =
    const loadTableData = (parameter: any) => new Promise<any>((resolve, reject) => {
      request.post(createApiUrl('/newlinkSass/finance/account/transfer/record'), { ...parameter })
        .then((res: any) => {
          resolve(res.data);
        }).catch((e: any) => {
          message.error(e.message);
        });
    });

    const tableRef = ref<any>(null);
    const onRefreshTable = () => {
      tableRef.value.refresh(true);
    };

    const view = () => {
      visible.value = true;
      // 二次显示刷新数据
      tableRef.value && nextTick(() => onRefreshTable());
    };

    const exporting = ref(false);
    const confirmVisible = ref(false);
    const onExport = () => {
      confirmVisible.value = true;
    };
    const onCancel = () => {
      exportParams.transactionTime = [];
    };
    const onConfirmExport = async () => {
      const params: Record<string, any> = {};
      if (exportParams.transactionTime.length) {
        params.createStartTime = exportParams.transactionTime[0].format('YYYY-MM-DD 00:00:00');
        params.createEndTime = exportParams.transactionTime[1].format('YYYY-MM-DD 23:59:59');
      }
      let res;
      try {
        exporting.value = true;
        res = await request.post(createApiUrl('/newlinkSass/excel/export/account/transfer/record'), { ...params }, { responseType: 'blob' });
      } catch (e: Error & any) {
        return message.error(e.message || '导出报错');
      }
      exporting.value = false;
      const blob = new Blob([res.data], {
        type: 'application/octet-stream;charset=UTF-8',
        endings: 'transparent',
      });

      const url = URL.createObjectURL(blob);
      dispatchDownload('转账记录.xls', url);
      // 释放之前创建的URL对象
      window.URL.revokeObjectURL(url);
      onCancel();
      confirmVisible.value = false;
    };

    return {
      loading,
      visible,
      view,

      tableRef,
      tableColumns,
      loadTableData,
      onExport,
      exporting,
      exportParams,
      confirmVisible,
      onConfirmExport,
      onCancel,
    };
  },
});
