import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_app_table = _resolveComponent("app-table")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_modal, {
      visible: _ctx.visible,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visible) = $event)),
      class: "transformLogModal",
      title: "转账记录",
      width: "850px",
      footer: null
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_button, {
          class: "fl-right",
          type: "primary",
          ghost: "",
          onClick: _ctx.onExport
        }, {
          default: _withCtx(() => [
            _createTextVNode("导出")
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_app_table, {
          ref: "tableRef",
          style: {"width":"90%"},
          "row-key": "confNo",
          columns: _ctx.tableColumns,
          data: _ctx.loadTableData,
          "show-pagination": true,
          "page-size": 10
        }, null, 8, ["columns", "data"])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_a_modal, {
      visible: _ctx.confirmVisible,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.confirmVisible) = $event)),
      title: "导出转账记录",
      "confirm-loading": _ctx.exporting,
      "ok-text": "导出",
      onOk: _ctx.onConfirmExport,
      onCancel: _ctx.onCancel
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          model: _ctx.exportParams,
          "label-col": { span: 5 }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, {
              label: "时间",
              class: "marginBottom"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_range_picker, {
                  value: _ctx.exportParams.transactionTime,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.exportParams.transactionTime) = $event)),
                  format: "YYYY-MM-DD",
                  placeholder: ['起始时间', '结束时间'],
                  style: {"width":"250px"}
                }, null, 8, ["value"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      _: 1
    }, 8, ["visible", "confirm-loading", "onOk", "onCancel"])
  ]))
}