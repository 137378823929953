// 账单状态
export const billStatusEnum: Array<any> = [
  { value: '', label: '全部' },
  { value: '3', label: '待客户确认' },
  { value: '4', label: '客户驳回' },
  { value: '5', label: '已对账' },
];

// 审核状态
export const auditStatusEnum:Record<string, string> = {
  0: '未审核',
  1: '通过',
  2: '驳回',
};
// 订单状态
export const orderStatusEnum:Record<string, string> = {
  '-1': '无效',
  0: '待支付',
  1: '已支付',
  2: '退款中',
  3: '部分支付',
  4: '订单取消',
  5: '已退款',
  6: '部分退款',
  7: '退款失败',
};
export const orderStatusListEnum:Record<string, string> = {
  1: '已支付',
  5: '已退款',
};
// 支付方式
export const payTypeEnum: Record<string, string> = {
  '-1': '无',
  1: '企业共享支付',
  2: '企业个人账户余额',
  3: '三方支付_支付宝',
  4: '三方支付_微信',
  5: '优惠券',
  6: '礼品卡',
  8: '车辆限额支付',
  9: '员工限额支付',
};


