
import { defineComponent, reactive, ref, computed } from 'vue';
import moment, { Moment } from 'moment';
import { noEmptyProp, not, dispatchDownload } from '@/utils/illuminate';
import type { TableColumn } from '@/components/Table';
import { request } from '@/utils/request';
import { formatDate } from '@/core/filters';
import { createApiUrl, sldPageTitle } from '@/utils/utils';
import { message } from 'ant-design-vue';
import { useRoute } from 'vue-router';

type FilterParamsType = {
  payTime: Moment[];
  orderNo: undefined | string;
}

const defaultFilterParams = {
  payTime: [],
  orderNo: undefined,
};

export default defineComponent({
  name: 'FinanceBillDetail',
  components: {},
  setup() {
    const route = useRoute();
    /** ----------------------------------------------------- 表单 ---------------------------------------------- */
    const filterParams = reactive<FilterParamsType>({ ...defaultFilterParams });
    const searchList = computed(() => [
      { 
        label: '订单号',
        name: 'orderNo',
        type: 'input',
        allowClear: true,
        placeholder: '订单号',
      },
      { 
        label: '支付时间',
        name: 'payTime',
        type: 'rangepicker',
        format: 'YYYY-MM-DD',
        placeholder: ['起始时间', '结束时间'],
        width: '250px',
      },
    ]);
    /** ----------------------------------------------------- 表格 ---------------------------------------------- */
    const tableRef = ref<any>(null);
    const tableData = ref([]);
    const tableColumns: TableColumn[] = [
      { title: '订单编号', dataIndex: 'orderNo', width: '150px', align: 'center' },
      { title: '订单状态', dataIndex: 'orderStatusShow', width: '150px', align: 'center' },
      { title: '能源类型', dataIndex: 'energyTypeShow', width: '120px', align: 'center' },
      { title: '员工姓名', dataIndex: 'userName', width: '120px', customRender: ({ text }) => text || '---', align: 'center' },
      { title: '手机号', dataIndex: 'phone', width: '120px', customRender: ({ text }) => text || '---', align: 'center' },
      { title: '部门名称', dataIndex: 'accountMainDeptName', width: '150px', customRender: ({ text }) => text || '---', align: 'center' },
      { title: '车牌号', dataIndex: 'licensePlateNo', width: '120px', customRender: ({ text }) => text || '---', align: 'center' },
      { title: '油站', dataIndex: 'gasName', width: '120px', align: 'center' },
      { title: '升数', dataIndex: 'num', width: '120px', align: 'center' },
      { title: '订单金额（元）', dataIndex: 'orderAmount', width: '150px', customRender: ({ text }) => +(text || 0).toFixed(2), align: 'center' },
      { title: '企业支付金额（元）', dataIndex: 'realAmount', width: '150px', customRender: ({ text }) => +(text || 0).toFixed(2), align: 'center' },
      { title: '优惠金额（元）', dataIndex: 'discountAmount', width: '150px', customRender: ({ text }) => +(text || 0).toFixed(2), align: 'center' },
      { title: '结算金额（元）', dataIndex: 'settlementAmount', width: '150px', customRender: ({ text }) => +(text || 0).toFixed(2), align: 'center' },
      { title: '可开票金额（元）', dataIndex: 'totalInvoiceAmount', width: '150px', customRender: ({ text }) => +(text || 0).toFixed(2), align: 'center' },
      { title: '支付方式', dataIndex: 'payTypeShow', width: '120px', align: 'center' },
      { title: '下单时间', dataIndex: 'orderDt', width: '200px', customRender: ({ text }) => formatDate(text), align: 'center' },
      { title: '支付时间', dataIndex: 'payDt', width: '200px', customRender: ({ text }) => formatDate(text), align: 'center' },
      { title: '退款时间', dataIndex: 'refundDt', width: '200px', customRender: ({ text }) => formatDate(text), align: 'center' },
    ];
    const loadTableData = async (parameter: any) => new Promise<void>((resolve, reject) => {
      try {
        const params = { ...not(noEmptyProp(filterParams), ['payTime']), ...parameter };
        if (filterParams.payTime.length) {
          params.payDtGte = moment(filterParams.payTime[0]).format('YYYY-MM-DD 00:00:00');
          params.payDtLte = moment(filterParams.payTime[1]).format('YYYY-MM-DD 23:59:59');
        }
        request.post(createApiUrl('/newlinkSass/accountStatementBill/detail/list'), { billId: route.query.id, ...params }, { noEnterpriseId: true })
          .then(res => {
            tableData.value = res.data.list || [];
            resolve(res.data);
          });
      } catch (e:any) {
        message.error(e.message);
      }
    });
    const onRefreshTable = () => {
      tableRef.value.refresh();
    };
    // = ---------------------------- = 导出数据 = ---------------------------- =
    const onExport = async () => {
      if (!tableData.value.length) return message.info('无可导出订单，请查询');
      try {
        const params: any = { ...noEmptyProp(not(filterParams, ['payTime'])) };
        if (filterParams.payTime && filterParams.payTime.length) {
          params.payDtGte = moment(filterParams.payTime[0].format('YYYY-MM-DD 00:00:00')).valueOf();
          params.payEndTime = moment(filterParams.payTime[1].format('YYYY-MM-DD 23:59:59')).valueOf();
        }
        const res = await request.post(createApiUrl('/newlinkSass/excel/accountStatementBill/detail/export'), { billId: route.query.id, ...params }, { responseType: 'blob' });
        const blob = new Blob([res.data], {
          type: 'application/octet-stream;charset=UTF-8',
          endings: 'transparent',
        });

        const url = URL.createObjectURL(blob);
        dispatchDownload('加油对账单明细.xlsx', url);
        // 释放之前创建的URL对象
        window.URL.revokeObjectURL(url);
      } catch (e: Error & any) {
        return message.error(e.message || '导出报错');
      }
    };

    return {
      searchList,
      filterParams,
      tableRef,
      tableColumns,
      loadTableData,
      onRefreshTable,
      sldPageTitle,
      onExport,
    };
  },
});
