import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_List = _resolveComponent("List")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createBlock(_component_a_card, { class: "utils__pageContainer bill__list" }, {
    default: _withCtx(() => [
      _createVNode(_component_a_tabs, {
        activeKey: _ctx.activeKey,
        "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeKey) = $event)),
        class: "card__tab"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (item) => {
            return (_openBlock(), _createBlock(_component_a_tab_pane, {
              key: item.value,
              tab: item.label
            }, {
              default: _withCtx(() => [
                _createVNode(_component_List, {
                  type: item.value,
                  "active-key": _ctx.activeKey,
                  label: item.label
                }, null, 8, ["type", "active-key", "label"])
              ]),
              _: 2
            }, 1032, ["tab"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["activeKey"])
    ]),
    _: 1
  }))
}