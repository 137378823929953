
import { message } from 'ant-design-vue';
import { defineComponent, reactive, ref, watch, computed } from 'vue';
import { noEmptyProp, not, dispatchDownload } from '@/utils/illuminate';
import moment, { Moment } from 'moment';
import { createApiUrl, firstError, isValidateError } from '@/utils/utils';
import type { TableColumn } from '@/components/Table';
import { request } from '@/utils/request';
import { formatDate } from '@/core/filters';
import { useCurrentEnterpriseHook } from '@/store';
import { useRouter } from 'vue-router';
import { billStatusEnum } from './dict';


type FilterParamsType = {
  createTime: Moment[];
  billStatus: string;
}

const defaultFilterParams = {
  createTime: [],
  billStatus: '',
};

export default defineComponent({
  components: {},
  props: {
    type: {
      type: Number,
      default: 1,
    },
    activeKey: {
      type: Number,
      default: 1,
    },
    label: {
      type: String,
      default: '',
    },
  },
  setup(props, ctx) {
    useCurrentEnterpriseHook('');
    const router = useRouter();
    const filterParams = reactive<FilterParamsType>({ ...defaultFilterParams });
    /** ----------------------------------------------------- 表单 ---------------------------------------------- */
    const searchList = computed(() => [
      { 
        label: '账单状态',
        name: 'billStatus',
        type: 'select',
        placeholder: '选择账单状态',
        allowClear: true, 
        fieldNames: { label: 'label', value: 'value' },
        sel_data: billStatusEnum,
      },
      { 
        label: '账单创建时间',
        name: 'createTime',
        type: 'rangepicker',
        format: 'YYYY-MM-DD',
        placeholder: ['起始时间', '结束时间'],
        width: '250px',
      },
    ]);
    /** ----------------------------------------------------- 表格 ---------------------------------------------- */
    const tableRef = ref<any>(null);
    const tableData = ref([]);
    const tableColumns: TableColumn[] = [
      { title: '账单编码', dataIndex: 'billNo', width: '200px', align: 'center' },
      { title: '公司ID', dataIndex: 'enterpriseId', width: '200px', align: 'center' },
      { title: '公司全称', dataIndex: 'enterpriseName', width: '200px', align: 'center' },
      { title: '结算方式', dataIndex: 'settlementModeShow', width: '120px', customRender: ({ text }) => text || '---', align: 'center' },
      { title: '结算周期', dataIndex: 'settlementPeriodShow', width: '120px', customRender: ({ text }) => text || '---', align: 'center' },
      { title: '账单时间', dataIndex: 'billStartTime', width: '200px', slots: { customRender: 'billStartTime' }, align: 'center' },
      { title: '账单生成时间', dataIndex: 'createTime', width: '200px', customRender: ({ text }) => formatDate(text), align: 'center' },
      { title: '消费升数', dataIndex: 'totalNum', width: '120px', align: 'center' },
      { title: '订单总金额（元）', dataIndex: 'totalOrderAmount', width: '150px', customRender: ({ text }) => +(text || 0).toFixed(2), align: 'center' },
      { title: '企业实付金额（元）', dataIndex: 'totalRealAmount', width: '150px', customRender: ({ text }) => +(text || 0).toFixed(2), align: 'center' },
      { title: '结算总金额（元）', dataIndex: 'totalSettlementAmount', width: '150px', customRender: ({ text }) => +(text || 0).toFixed(2), align: 'center' },
      { title: '账单状态', dataIndex: 'statusShow', width: '150px', align: 'center' },
      { title: '操作', dataIndex: 'action', width: '200px', slots: { customRender: 'action' }, fixed: 'right', align: 'center' },
    ];
    // 加载数据方法 必须为 Promise 对象
    const loadTableData = async (parameter: any) => new Promise<void>((resolve, reject) => {
      try {
        const params: any = { ...parameter, ...noEmptyProp(not(filterParams, ['createTime'])) };
        if (filterParams.createTime && filterParams.createTime.length) {
          params.createTimeGte = moment(filterParams.createTime[0]).format('YYYY-MM-DD 00:00:00');
          params.createTimeLte = moment(filterParams.createTime[1]).format('YYYY-MM-DD 23:59:59');
          delete params.createTime;
        }
        if (filterParams.billStatus) {
          params.statusList = [filterParams.billStatus];
          delete params.billStatus;
        }
        request.post(createApiUrl('/newlinkSass/accountStatementBill/list'), params, { noEnterpriseId: true })
          .then((res: any) => {
            tableData.value = res.data.list || [];
            resolve(res.data);
          })
          .catch((e: Error & any) => message.error(e.message));
      } catch (e: Error & any) {
        return message.error(e.message || '加载数据失败');
      }
    });

    // 重置
    const onRefreshTable = () => {
      tableRef.value.refresh(true);
    };

    const confirmVisible = ref(false);
    const confirmParams = reactive({ status: '1', refuseReason: '' });
    const confirmRules = {
      status: [{ required: true, message: '请选择入账状态', trigger: 'change' }],
      refuseReason: [{ required: true, message: '请选择驳回原因', trigger: 'change' }],
    };
    const detailInfos = reactive<Record<string, any>>({});
    const handlerOperate = (type: String, row: Record<string, any>) => {
      switch (type) {
        case 'confirm':
          confirmVisible.value = true;
          Object.assign(detailInfos, row);
          break;
        case 'detail':
          router.push({ name: 'finance.statementbill-detail', query: { id: row.id } });
          break;
        default:
      }
    };
    const onClose = () => {
      confirmVisible.value = false;
      confirmParams.status = '1';
      confirmParams.refuseReason = '';
    };
    const formRef = ref();
    const onConfirm = async () => {
      try {
        await formRef.value.validate();
        if (confirmParams.status === '1') await request.post(createApiUrl('/newlinkSass/accountStatementBill/confirm'), { billId: detailInfos.id });
        else await request.post(createApiUrl('/newlinkSass/accountStatementBill/refuse'), { billId: detailInfos.id, refuseReason: confirmParams.refuseReason });
        onRefreshTable();
        onClose();
      } catch (e: Error & any) {
        return message.error(isValidateError(e.message) ? firstError(e) : e.message);
      }
    };
    // = ---------------------------- = 导出数据 = ---------------------------- =
    const onExport = async () => {
      if (!tableData.value.length) return message.warning('无可导出订单，请查询');
      try {
        const params: any = { ...noEmptyProp(not(filterParams, ['createTime'])) };
        if (filterParams.createTime && filterParams.createTime.length) {
          params.createTimeGte = moment(filterParams.createTime[0]).format('YYYY-MM-DD 00:00:00');
          params.createTimeLte = moment(filterParams.createTime[1]).format('YYYY-MM-DD 23:59:59');
          delete params.createTime;
        }
        if (filterParams.billStatus) {
          params.statusList = [filterParams.billStatus];
          delete params.billStatus;
        }
        const res = await request.post(createApiUrl('/newlinkSass/excel/accountStatementBill/export'), params, { responseType: 'blob' });
        const blob = new Blob([res.data], {
          type: 'application/octet-stream;charset=UTF-8',
          endings: 'transparent',
        });

        const url = URL.createObjectURL(blob);
        dispatchDownload('加油对账单.xlsx', url);
        // 释放之前创建的URL对象
        window.URL.revokeObjectURL(url);
      } catch (e: Error & any) {
        return message.error(e.message || '导出报错');
      }
    };

    // tabs 切换刷新表格
    watch(() => props.activeKey, (val: number) => {
      if (val === props.type) {
        Object.assign(filterParams, { ...defaultFilterParams });
        onRefreshTable();
      }
    });

    return {
      searchList,
      filterParams,
      tableColumns,
      loadTableData,
      tableRef,
      onRefreshTable,
      onExport,
      handlerOperate,
      confirmVisible,
      confirmParams,
      confirmRules,
      onConfirm,
      onClose,
      formatDate,
      formRef,
    };
  },
});
