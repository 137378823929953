
import { defineComponent, reactive, ref, watch, computed, onMounted } from 'vue';
import { message } from 'ant-design-vue';
import { request } from '@/utils/request';
import { createApiUrl, firstError, isValidateError } from '@/utils/utils';

import { useStore } from 'vuex';
import { RuleObject } from 'ant-design-vue/es/form/interface';

type FormType = {
  withdrawalEnterpriseId: number;
  withdrawalEnterpriseName: string,
  amount: string,
  refundAccountBankName: string | undefined,
  refundAccountBankCode: string | undefined,
  depositBankSubBranch: string | undefined,
  depositBankSubBranchCode: string | undefined,
  refundAccountBankNum: string | undefined,
  reason: string | undefined,
  balance: number,
  contractingPartiesId: number | undefined,
}

export default defineComponent({
  name: 'ApplyWithDrawDeposit',
  props: {

  },
  emits: ['complete'],
  setup(props, ctx) {
    const store = useStore();
    const visible = ref(false);
    const defaultFormData = {
      withdrawalEnterpriseId: store.state.User.enterprise?.enterpriseId,
      withdrawalEnterpriseName: store.state.User.enterprise?.enterpriseName,
      amount: '',
      refundAccountBankName: undefined,
      refundAccountBankCode: undefined,
      depositBankSubBranch: undefined,
      depositBankSubBranchCode: undefined,
      refundAccountBankNum: undefined,
      reason: undefined,
      balance: 0,
      contractingPartiesId: undefined,
    };
    const formData = reactive<FormType>({ ...defaultFormData });
    const superBankCode = ref();
    const step = ref('pre');
    const contractingPartiesFlag = ref(false);
    const contractingPartiesList = ref([]);

    // 自定义校验
    const validateField = async (rule: RuleObject, value: string) => {
      if (Number(value) <= 0) {
        return Promise.reject('提现金额需大于0');
      }
      if (!/^(\+?)\d+(\.\d{0,2})?$/.test(value)) {
        return Promise.reject('只能输入数字最多两位小数');
      }
      if (Number(value) > Number(formData.balance)) {
        return Promise.reject('提现金额不能大于企业当前余额'); 
      }
      return Promise.resolve();
    };

    const rules = reactive({
      amount: [{ required: true, message: '请输入金额', trigger: ['change', 'blur'] }, { validator: validateField, trigger: ['change', 'blur'] }],
      refundAccountBankName: [{ required: true, message: '请输入开户银行', trigger: ['change', 'blur'] }],
      depositBankSubBranch: [{ required: true, message: '请输入开户支行', trigger: ['change', 'blur'] }],
      refundAccountBankNum: [{ required: true, message: '请输入银行账号', trigger: ['change', 'blur'] }],
      reason: [{ required: true, message: '请输入提现原因', trigger: ['change', 'blur'] }],
      contractingPartiesId: [{ type: 'number', required: true, message: '请选择签约主体', trigger: 'change' }],
    });

    const closeModal = (flag = false) => {
      visible.value = false;
      step.value = 'pre'; 
      contractingPartiesFlag.value = false;
      Object.assign(formData, defaultFormData);
      superBankCode.value = '';
      flag && ctx.emit('complete');
    };
    
    const formRef = ref();
    const toNext = async () => {
      console.log('toNext');
      try {
        await formRef.value.validate();
        step.value = 'next';
      } catch (e: Error & any) {
        return message.error(isValidateError(e) ? firstError(e) : e.message);
      }
    }; 

    const toPre = () => {
      step.value = 'pre'; 
    };

    const onSubmit = async () => {
      try {
        const params = { ...formData };
        const { data = {} } = await request.post(createApiUrl('/newlinkSass/finance/apply-withdrawal'), { ...params });
        message.success('提现申请已提交成功！');
        closeModal(true);
      } catch (e: Error & any) {
        return message.error(isValidateError(e) ? firstError(e) : e.message);
      }
    };
    

    /* ----------------------- 开户银行 -------------------------- */
    const accountBankNameList = ref([]);
    const handleBankNameSearch = (value: any) => {
      if (value) {
        request.post(createApiUrl('/newlinkSass/finance/queryBankAccount'), { bankQueryName: value }).then((res: any) => {
          const { data, code } = res;
          if (code !== 200) return message.error(res.message);
          accountBankNameList.value = data.bankList || [];
        });
      }
    };
    const handleChangeBank = (bankCode: string) => {
      const cur: { bankName: string | undefined, bankCode: string | undefined, superBankCode: string | undefined } = accountBankNameList.value.find((item: any) => item.bankCode === bankCode) || { bankName: undefined, bankCode: undefined, superBankCode: undefined };
      formData.refundAccountBankName = cur.bankName;
      formData.refundAccountBankCode = cur.bankCode;
      superBankCode.value = cur.superBankCode;
      formData.depositBankSubBranch = undefined;
      formData.depositBankSubBranchCode = undefined;
    };


    /* -----------------------开户支行--------------------------------- */
    const accountBankBranchList = ref([]);
    const handleBankBranchSearch = (value: any) => {
      if (!superBankCode.value) return message.error('请先选择开户银行');
      if (value) {
        request.post(createApiUrl('/newlinkSass/finance/queryBranchBankAccount'), { superBankCode: superBankCode.value, bankBranchQueryName: value }).then((res: any) => {
          const { data, code } = res;
          if (code !== 200) return message.error(res.message);
          accountBankBranchList.value = data.bankBranchList || [];
        });
      }
    };
    const handleChangeSubBank = (branchBankCode: string) => {
      const cur: { branchBankName: string | undefined, branchBankCode: string | undefined } = accountBankBranchList.value.find((item:any) => item.branchBankCode === branchBankCode) || { branchBankName: undefined, branchBankCode: undefined };
      formData.depositBankSubBranch = cur.branchBankName;
      formData.depositBankSubBranchCode = cur.branchBankCode;
    };

    const handleAll = () => {
      formData.amount = formData.balance.toString();
    };

    const balanceAmount = computed(() => { 
      if (Number.isNaN(Number(formData.amount))) return; 
      const cur = Number(formData.balance) - Number(formData.amount);
      return cur.toFixed(2);
    });

    watch(() => formData.refundAccountBankName, newVal => {
      if (!newVal) {
        formData.depositBankSubBranch = undefined;
        formData.depositBankSubBranchCode = undefined;
        superBankCode.value = undefined;
      }
    });

    const getCurrentEnterpriseInfos = async (enterpriseId: number) => {
      visible.value = true;
      try {
        const res = await Promise.all([
          request.get(createApiUrl('/newlinkSass/finance/getFinanceInfo'), { params: { enterpriseId } }),
          request.get(createApiUrl('/newlinkSass/finance/getChangeContractFlag'), { params: { enterpriseId } }),
        ]);
        const [financeInfoRes, contractRes] = res;
        if (financeInfoRes.code !== 200 || contractRes.code !== 200) return message.error(financeInfoRes.message || contractRes.message);
        Object.assign(formData, financeInfoRes.data);
        formData.reason = '';
        formData.contractingPartiesId = undefined;
        contractingPartiesFlag.value = contractRes.data;
      } catch (e: Error & any) {
        message.error(e.message);
      }
    };

    const changeEnterprise = (obj: any) => {
      formData.withdrawalEnterpriseId = obj.enterpriseId;
      formData.withdrawalEnterpriseName = obj.enterpriseName;     
      formData.amount = '';
      getCurrentEnterpriseInfos(formData.withdrawalEnterpriseId);
    };

    // 获取签约主体列表
    const getContractingPartiesList = async () => {
      const res = await request.post(createApiUrl('/newlinkSass/finance/parties/list'), { type: 1 });
      if (res.code !== 200) return message.error(res.message);
      contractingPartiesList.value = res.data;
    };

    const contractingPartiesName = computed(() => {
      const { companyName } = contractingPartiesList.value.find((item: any) => item.id === formData.contractingPartiesId) || { companyName: '-' };
      return companyName;
    });

    onMounted(() => {
      getContractingPartiesList();
    });


    return {
      step,
      formData,
      formRef,
      visible,
      toNext,
      toPre,
      onSubmit,
      handleBankNameSearch,
      handleChangeBank,
      accountBankNameList,
      handleBankBranchSearch,
      handleChangeSubBank,
      accountBankBranchList,
      handleAll,
      balanceAmount,
      rules,
      closeModal,
      getCurrentEnterpriseInfos,
      changeEnterprise,
      contractingPartiesFlag,
      contractingPartiesList,
      contractingPartiesName,
    };
  },
});
