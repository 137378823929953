
import { message } from 'ant-design-vue';
import { defineComponent, reactive, ref, getCurrentInstance, onMounted } from 'vue';


import type { TableColumn } from '@/components/Table';
import { request } from '@/utils/request';
import { createApiUrl, sldPageTitle } from '@/utils/utils';
import { useStore } from 'vuex';

import ApplyWithDrawDeposit from './parts/ApplyWithDrawDeposit.vue';
import WidthDrawDepositInfos from './parts/WithDrawDepositInfos.vue';

export default defineComponent({
  name: 'WidthDrawDeposit',
  components: {
    ApplyWithDrawDeposit,
    WidthDrawDepositInfos,
  },
  setup(prop, ctx) {
    const instance = getCurrentInstance()?.proxy;
    const tableRef = ref<any>(null);
    const store = useStore();

    const tableColumns: TableColumn[] = [
      { title: '企业名称', dataIndex: 'enterpriseName' },
      { title: '提现金额(元)', dataIndex: 'amount', slots: { customRender: 'amount' } },
      { title: '提交时间', dataIndex: 'submitTime' },
      { title: '处理时间', dataIndex: 'checkTime' },
      { title: '提交人', dataIndex: 'submitterName' },
      { title: '状态', dataIndex: 'approvalStatus' },
      { title: '操作', dataIndex: 'operate', slots: { customRender: 'operate' } },
    ];

    const loadTableData = async (parameter: any) => {
      const params = { ...parameter };

      const { data } = await request.get(createApiUrl('/newlinkSass/finance/get-withdrawal-records'), { params, noEnterpriseId: true });
      return data;
    };

    const onRefreshTable = () => {
      tableRef.value.refresh();
    };

    const withDrawDepositInfosRef = ref();
    const withdrawDepositRecord = (record: any) => {
      withDrawDepositInfosRef.value.getDetail(record);
    };

    const applyWithDrawDepositRef = ref();
    const applyWithdrawDeposit = () => {
      applyWithDrawDepositRef.value.getCurrentEnterpriseInfos(store.state.User.enterprise?.enterpriseId);
    };

    onMounted(() => {
      instance?.$sensors.track('es_saas_recharge_cash_out_page_view', { page_name: '充值/余额管理_提现_浏览' });
    });

    return {
      tableRef,
      tableColumns,
      loadTableData,
      onRefreshTable,
      withDrawDepositInfosRef,
      withdrawDepositRecord,
      applyWithDrawDepositRef,
      applyWithdrawDeposit,
      sldPageTitle,
    };
  },
});
