import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-primary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_enterprise_input = _resolveComponent("app-enterprise-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_app_input_number = _resolveComponent("app-input-number")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: _ctx.visible,
    "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.visible) = $event)),
    class: "transformModal",
    title: "转账",
    width: "450px",
    "ok-text": "转账",
    onOk: _ctx.onSubmit,
    onCancel: _ctx.onCancel
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_form, {
        ref: "formRef",
        class: "issueHoupiaoForm__form",
        model: _ctx.formData,
        rules: _ctx.formRules,
        "label-col": _ctx.labelCol,
        "wrapper-col": _ctx.wrapperCol
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form_item, {
            label: "转入公司",
            name: "otherSideEnterpriseId"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_app_enterprise_input, {
                value: _ctx.formData.otherSideEnterpriseId,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.otherSideEnterpriseId) = $event)),
                "no-group": "",
                "disable-in-open": "",
                mode: "list"
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            label: "转账金额",
            name: "transferAmount",
            style: {"margin-bottom":"0"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_app_input_number, {
                value: _ctx.formData.transferAmount,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.transferAmount) = $event)),
                class: "finance-input",
                placeholder: "请输入金额"
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, { "wrapper-col": { offset: 6, span: 14 } }, {
            default: _withCtx(() => [
              _createTextVNode(" 可转账金额："),
              _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.formatWithIntl(_ctx.financeDetail.availableAmount)), 1),
              _createTextVNode(" 元 ")
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "rules", "label-col", "wrapper-col"])
    ]),
    _: 1
  }, 8, ["visible", "onOk", "onCancel"]))
}