
import { defineComponent, ref } from 'vue';

export default defineComponent<{}, {}, {}, {}, any>({
  name: 'TopUpQrcodeModal',
  setup(props, ctx) {

    const visible = ref(false);

    const showModal = () => {
      visible.value = true;
    };

    const handleOk = (e: any) => {
      visible.value = false;
    };

    return {
      visible,
      showModal,
      handleOk,
    };
  },
});
