
import { defineComponent, reactive, ref, watch, computed, onMounted } from 'vue';
import { message } from 'ant-design-vue';
import { request } from '@/utils/request';
import { createApiUrl, firstError, isValidateError } from '@/utils/utils';


export default defineComponent({
  name: 'WidthDrawDepositInfos',
  props: {

  },
  setup(props, ctx) {
    const visible = ref(false);
    const formData = ref({ tableData: [] });

    const tableRef = ref();

    const closeModal = () => {
      visible.value = false;
    };

    const getDetail = (record: any) => {
      visible.value = true;
      request.get(createApiUrl('/newlinkSass/finance/get-withdraw-record-detail'), { params: { id: record.id } }).then((res: any) => {
        const { data, code } = res;
        if (code !== 200) return message.error(res.message);
        const { logs = [], ...others } = data;
        formData.value = { ...others, tableData: logs };
      });
    };

    return {
      formData,
      visible,
      tableRef,
      closeModal,
      getDetail,
    };
  },
});
