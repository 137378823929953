import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-347493a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_form = _resolveComponent("app-form")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_app_operates = _resolveComponent("app-operates")!
  const _component_app_table = _resolveComponent("app-table")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createBlock(_component_a_card, { class: "utils__pageContainer topUpRecordView" }, {
    title: _withCtx(() => [
      _createElementVNode("div", {
        innerHTML: _ctx.sldPageTitle('#1890ff', '对账单明细')
      }, null, 8, _hoisted_1)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_app_form, {
        "search-list": _ctx.searchList,
        "search-data": _ctx.filterParams,
        onHandleSearch: _ctx.onRefreshTable
      }, null, 8, ["search-list", "search-data", "onHandleSearch"]),
      _createVNode(_component_app_operates, { mg: "0 0 10px 0" }, {
        default: _withCtx(() => [
          _createVNode(_component_a_button, {
            type: "primary",
            onClick: _ctx.onExport
          }, {
            default: _withCtx(() => [
              _createTextVNode("导出")
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      }),
      _createVNode(_component_app_table, {
        ref: "tableRef",
        size: "default",
        "row-key": "id",
        "show-pagination": true,
        columns: _ctx.tableColumns,
        data: _ctx.loadTableData,
        scroll: { x:'100%', y: 500 }
      }, null, 8, ["columns", "data"])
    ]),
    _: 1
  }))
}