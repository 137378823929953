
import { defineComponent, reactive, ref, getCurrentInstance, onMounted } from 'vue';
import moment, { Moment } from 'moment';
import { noEmptyProp, not } from '@/utils/illuminate';
import type { TableColumn } from '@/components/Table';
import { request } from '@/utils/request';
import { createApiUrl } from '@/utils/utils';

export default defineComponent({
  setup(prop, ctx) {
    const instance = getCurrentInstance()?.proxy;
    const filterParams = reactive<{
      queryEnterpriseId: string | number;
      discountCardId: string | number;
      freezeReason: string | number;
      status: string | number;
      whereaboutsOfFunds: string | number;
      timeRange: Moment[];
      timeRange1: Moment[];
    }>({
      queryEnterpriseId: '',
      discountCardId: '',
      freezeReason: '',
      status: '',
      whereaboutsOfFunds: '',
      timeRange: [],
      timeRange1: [],
    });
    const tableRef = ref<any>(null);
    const dataSource = ref([]);

    const tableColumns: TableColumn[] = [
      { title: '记录编号', dataIndex: 'id' },
      { title: '企业名称', dataIndex: 'enterpriseName' },
      { title: '冻结原因', dataIndex: 'freezeReason' },
      { title: '业务编号', dataIndex: 'discountCardId' },
      { title: '账户类型', dataIndex: 'accountType' },
      { title: '冻结金额(元)', dataIndex: 'freezeAmount', slots: { customRender: 'freezeAmount' } },
      { title: '状态', dataIndex: 'status' },
      { title: '资金去向', dataIndex: 'whereaboutsOfFunds' },
      { title: '冻结时间', dataIndex: 'freezeTime' },
      { title: '解冻时间', dataIndex: 'unfreezeTime' },
    ];

    const loadTableData = async (parameter: any) => {
      const params = {
        ...not(noEmptyProp(filterParams), ['timeRange', 'timeRange1']),
        ...parameter,
      };

      if (filterParams.timeRange.length) {
        params.freezeTimeStart = filterParams.timeRange[0].format('yyyy-MM-DD 00:00:00');
        params.freezeTimeEnd = filterParams.timeRange[1].format('yyyy-MM-DD 23:59:59');
      }
      if (filterParams.timeRange1.length) {
        params.unfreezeTimeStart = filterParams.timeRange1[0].format('yyyy-MM-DD 00:00:00');
        params.unfreezeTimeEnd = filterParams.timeRange1[1].format('yyyy-MM-DD 23:59:59');
      }

      const { data } = await request.post(createApiUrl('/newlinkSass/discountCard/freeze/queryByPage'), params, { noEnterpriseId: true });
      return data;
    };

    const onRefreshTable = () => {
      tableRef.value.refresh();
    };

    onMounted(() => {
      instance?.$sensors.track('es_saas_recharge_frozen_page_view', { page_name: '充值/余额管理_冻结记录_浏览' });
    });

    return {
      filterParams,
      tableRef,
      tableColumns,
      loadTableData,
      onRefreshTable,
      dataSource,
    };
  },
});
