
import { defineComponent, reactive, ref, onMounted, watch } from 'vue';
import { message, Modal } from 'ant-design-vue';
import type { TableColumn } from '@/components/Table';

type deptInfoType = {
  departmentId: number,
  departmentName: string,
  enterpriseId: number,
  enterpriseName: string,
  errMsg: string
} 

export default defineComponent({
  props: {
    resultInfo: { type: Object, default: () => ({}) },
  },
  emits: ['close'],
  setup(props, ctx) {
    const visible = ref<boolean>(true);
    const loading = ref<boolean>(false);
    const handleCancel = () => {
      ctx.emit('close');
    };
    const handleOk = () => {
      ctx.emit('close');
    };

    const tableData = ref<deptInfoType[]>([]);
    tableData.value = props.resultInfo.failureResult as deptInfoType[];
    const columns:TableColumn[] = [
      { title: '公司', dataIndex: 'enterpriseName', customRender: ({ text, record }): string => (text ? `${text} (${record.enterpriseId})` : '') },
      { title: '部门', dataIndex: 'departmentName', customRender: ({ text, record }): string => (text ? `${text} (${record.departmentId})` : '') },
      { title: '错误原因', dataIndex: 'errMsg' },
    ];
    
    return {
      visible,
      loading,
      handleCancel,
      handleOk,
      tableData,
      columns,
    };
  },
});
