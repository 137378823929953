import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-12f03014"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "routeTab" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_app_operates = _resolveComponent("app-operates")!
  const _component_app_table = _resolveComponent("app-table")!
  const _component_ApplyWithDrawDeposit = _resolveComponent("ApplyWithDrawDeposit")!
  const _component_WidthDrawDepositInfos = _resolveComponent("WidthDrawDepositInfos")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createBlock(_component_a_card, _mergeProps(_ctx.$attrs, { class: "utils__pageContainer withRouteTab topUpRecordView" }), {
    title: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_router_link, { to: { name: 'finance.top_up' } }, {
          default: _withCtx(() => [
            _createTextVNode("充值")
          ]),
          _: 1
        }, 8, ["to"]),
        _createVNode(_component_router_link, { to: { name: 'finance.top-up-record' } }, {
          default: _withCtx(() => [
            _createTextVNode("充值记录")
          ]),
          _: 1
        }, 8, ["to"]),
        _createVNode(_component_router_link, { to: { name: 'finance.freeze-record' } }, {
          default: _withCtx(() => [
            _createTextVNode("冻结记录")
          ]),
          _: 1
        }, 8, ["to"]),
        _createVNode(_component_router_link, { to: { name: 'finance.limit-reminder' } }, {
          default: _withCtx(() => [
            _createTextVNode("额度提醒")
          ]),
          _: 1
        }, 8, ["to"]),
        _createVNode(_component_router_link, { to: { name: 'finance.withdraw-deposit' } }, {
          default: _withCtx(() => [
            _createTextVNode("提现")
          ]),
          _: 1
        }, 8, ["to"])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_app_operates, {
        mg: "0 0 10px 0",
        jc: "space-between"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_button, {
            type: "primary",
            onClick: _ctx.applyWithdrawDeposit
          }, {
            default: _withCtx(() => [
              _createTextVNode("申请提现")
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      }),
      _createVNode(_component_app_table, {
        ref: "tableRef",
        size: "default",
        "row-key": "id",
        columns: _ctx.tableColumns,
        data: _ctx.loadTableData,
        "show-pagination": true
      }, {
        amount: _withCtx(({ record }) => [
          _createElementVNode("span", null, "￥" + _toDisplayString(record.amount.toFixed(2)), 1)
        ]),
        operate: _withCtx(({ record }) => [
          _createElementVNode("a", {
            href: "#",
            onClick: _withModifiers(($event: any) => (_ctx.withdrawDepositRecord(record)), ["prevent"])
          }, "查看详情", 8, _hoisted_2)
        ]),
        _: 1
      }, 8, ["columns", "data"]),
      _createVNode(_component_ApplyWithDrawDeposit, {
        ref: "applyWithDrawDepositRef",
        onComplete: _ctx.onRefreshTable
      }, null, 8, ["onComplete"]),
      _createVNode(_component_WidthDrawDepositInfos, { ref: "withDrawDepositInfosRef" }, null, 512)
    ]),
    _: 1
  }, 16))
}