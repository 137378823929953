
import { defineComponent, nextTick, onMounted, reactive, ref, watch } from 'vue';
import { parseNumberOrZero } from '@/utils/illuminate';
import { message } from 'ant-design-vue';
import { useStore } from 'vuex';
import { useFinance } from '@/views/finance/parts/utils';
import { request } from '@/utils/request';
import { createApiUrl, firstError, isValidateError, toApiUnit, toLocalUnit } from '@/utils/utils';

const defaultFormData = {
  otherSideEnterpriseId: undefined,
  recycleAmount: '',
};

export default defineComponent({
  name: 'RecycleModal',
  emits: ['complete'],
  setup(props, ctx) {
    const store = useStore();
    const visible = ref(false);

    const formData = reactive<{
      otherSideEnterpriseId?: number,
      recycleAmount: string,
    }>({
      ...defaultFormData,
    });
    /* = ---------------------------- = 当前企业余额 = ---------------------------- = */
    const currentEnterpriseBlance = ref(0);
    const currentEnterpriseBlanceLoaded = ref(false);
    const loadCurrentEnterprisBlance = async (id: number) => {
      currentEnterpriseBlanceLoaded.value = false;
      let res: any;
      try {
        res = await request.get(createApiUrl('/newlinkSass/finance/account/find-balance'), { params: { queryEnterpriseId: id } });
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      currentEnterpriseBlanceLoaded.value = true;
      currentEnterpriseBlance.value = toLocalUnit(res.data);
    };

    watch(() => formData.otherSideEnterpriseId, val => (val && loadCurrentEnterprisBlance(val)));

    const formRules = {
      otherSideEnterpriseId: [{
        validator(rule: any, value: any) {
          return value ? Promise.resolve() : Promise.reject('请选择回收公司');
        },
        trigger: 'blur',
      }],
      recycleAmount: [
        { required: true, message: '请输入回收金额', trigger: 'blur' },
        {
          validator(rule: any, value: any) {
            const valNum = parseNumberOrZero(value);
            return valNum <= 0
              ? Promise.reject('回收金额必须大于 0')
              : valNum > parseNumberOrZero(currentEnterpriseBlance.value)
                ? Promise.reject('回收金额不可大于"可回收金额"')
                : Promise.resolve();
          },
          trigger: 'blur',
        },
      ],
    };

    const formRef = ref<any>(null as any);
    const submiting = ref(false);

    const onCancel = () => {
      currentEnterpriseBlanceLoaded.value = false;
      Object.assign(formData, defaultFormData);
      formRef.value.clearValidate();
    };

    const onSubmit = async () => {
      try {
        await formRef.value.validate();
        await request.post(createApiUrl('/newlinkSass/finance/enterprise/recycle'), {
          ...formData,
          enterpriseId: store.state.User!.enterprise!.enterpriseId,
          recycleAmount: toApiUnit(formData.recycleAmount),
        });
      } catch (e: Error & any) {
        return message.error(isValidateError(e.message) ? firstError(e) : e.message);
      }

      message.success('回收完成');
      visible.value = false;
      ctx.emit('complete');
      onCancel();
    };

    const recycle = () => {
      visible.value = true;
    };

    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },

      currentEnterpriseBlance,
      currentEnterpriseBlanceLoaded,

      formData,
      formRules,

      visible,
      recycle,

      formRef,
      submiting,
      onSubmit,
      onCancel,
    };
  },
});
