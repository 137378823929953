
import { defineComponent, onMounted, reactive, ref, watch, getCurrentInstance } from 'vue';
import { message } from 'ant-design-vue';
import copy from 'copy-to-clipboard';

import { formatCny } from '@/utils/illuminate';
import { useStore } from 'vuex';
import { request } from '@/utils/request';
import { createApiUrl } from '@/utils/utils';
import { useFinance } from '@/views/finance/parts/utils';
import { TIPS_LIST } from '@/utils/constants';

import { RootState } from '@/store';
import { isDebuging } from '@/utils/env';

import TopUpQrcodeModal from './parts/TopUpQrcodeModal.vue';
import DialogQrCode from './parts/DialogQrCodes.vue';
import TransformLog from './parts/TransformLog.vue';
import AllCompanyBlance from './parts/AllCompanyBlance.vue';
import TransferModal from './parts/TransferModal.vue';
import RecycleModal from './parts/RecycleModal.vue';

const MAX_AMOUNT = 10000;

const moneyInputTip = `充值金额为1000.00-${MAX_AMOUNT}.00元，请重新输入`;

export default defineComponent({
  name: 'TopUpView',
  components: {
    TopUpQrcodeModal,
    DialogQrCode,
    TransformLog,
    AllCompanyBlance,
    TransferModal,
    RecycleModal,
  },
  setup(props, ctx) {
    const instance = getCurrentInstance()?.proxy;
    const store = useStore<RootState>();
    const financeState = useFinance();
    const userCustom = ref(false);
    const qrcode = ref(null as any);
    const moneyInputError = ref('');
    const serverError = ref('');
    const submitLoading = ref(false);

    const formData = reactive({
      name: '',
      mobile: '',
      invoiceType: '2',
      selectAmount: 1000,
      userAmount: '',
      payType: 3,
    });

    watch(
      () => [formData.selectAmount, formData.userAmount],
      () => { serverError.value = ''; },
    );

    const rules = {
      name: [{ validator: () => Promise.resolve(''), trigger: 'change' }],
    };

    const userSelectMount = (amount: number) => {
      formData.selectAmount = amount;
      userCustom.value = false;
      formData.userAmount = '';
      moneyInputError.value = '';
    };

    const topUpModalRef = ref(null as any);
    const onSubmit = () => {
      topUpModalRef.value.showModal();
    };

    const showQrcodeModel = async () => {
      if ((formData.selectAmount === 0 && formData.userAmount === '') || moneyInputError.value) {
        moneyInputError.value = moneyInputTip;
        return;
      }

      submitLoading.value = true;
      const params = {
        payType: formData.payType,
        enterpriseId: store.state.User!.enterprise!.enterpriseId,
        enterpriseName: store.state.User!.enterprise!.enterpriseName,
        amount: formData.selectAmount === 0 ? Number.parseInt(formData.userAmount, 10) * 100 : formData.selectAmount * 100,
      };

      // 调试模式
      if (isDebuging()) params.amount /= 100000;

      let res: any;
      try {
        res = await request.post(createApiUrl('/newlinkSass/pay/prepare-pay'), params);
      } catch (e: Error & any) {
        if (e.code === 2336) serverError.value = e.message;
        else message.error(e.message);
        return;
      } finally {
        submitLoading.value = false;
      }

      qrcode.value.showDialog({ ...res.data, payType: formData.payType });
    };

    const backInfo = ref<{
      backAccount: string;
      backName: string;
      companyName: string;
    }[]>([{
      backAccount: '',
      backName: '',
      companyName: '',
    }]);

    const loadBankAccount = async () => {
      let res: any;
      try {
        res = await request.get(createApiUrl('/newlinkSass/finance/find-bank'));
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      backInfo.value = res.data;
    };

    const onCopyAccount = (text: string) => {
      // Copy with options
      const copyRes = copy(text);

      if (copyRes) {
        message.success('已复制');
      } else {
        message.error('复制失败');
      }
    };

    const changePayType = (t: any) => {
      formData.payType = t;
    };

    const onFocusMoneyInput = () => {
      moneyInputError.value = '';
      serverError.value = '';
    };

    const onBlurMoneyInput = () => {
      if (Number.parseInt(formData.userAmount, 10) > MAX_AMOUNT || Number.parseInt(formData.userAmount, 10) < 1000) {
        moneyInputError.value = moneyInputTip;
      } else if (formData.userAmount !== '') {
        formData.userAmount = formatCny(formData.userAmount);
        formData.selectAmount = 0;
      }
    };

    const onPayComplete = () => {
      formData.payType = 3;
      formData.userAmount = '';
      formData.selectAmount = 1000;
      financeState.loadFinanceDetail();
    };

    /* = ---------------------------- = 查看转账记录 = ---------------------------- = */
    const transformLogRef = ref<any>(null);
    const onViewTransformLog = () => {
      transformLogRef.value.view();
    };

    /* = ---------------------------- = 子公司账户余额 = ---------------------------- = */
    const allCompanyBlanceRef = ref<any>(null);
    const onViewAllCompanyBlance = () => {
      allCompanyBlanceRef.value.view();
    };

    /* = ---------------------------- = 转账 = ---------------------------- = */
    const transferModalRef = ref<any>(null);
    const onTransfer = () => {
      transferModalRef.value.transfer();
    };

    /* = ---------------------------- = 回收 = ---------------------------- = */
    const recycleModalRef = ref<any>(null);
    const onRecycle = () => {
      recycleModalRef.value.recycle();
    };

    onMounted(() => {
      instance?.$sensors.track('es_saas_recharge_page_view', { page_name: '充值/余额管理_充值_浏览' });
      financeState.loadFinanceDetail();
      loadBankAccount();
    });

    return {
      ...financeState,
      TIPS_LIST,
      formData,
      rules,
      submitLoading,

      userSelectMount,
      userCustom,

      topUpModalRef,
      onSubmit,

      backInfo,
      onCopyAccount,

      changePayType,
      qrcode,
      showQrcodeModel,
      onPayComplete,
      moneyInputError,
      serverError,
      onFocusMoneyInput,
      onBlurMoneyInput,

      transformLogRef,
      onViewTransformLog,

      allCompanyBlanceRef,
      onViewAllCompanyBlance,

      transferModalRef,
      onTransfer,

      recycleModalRef,
      onRecycle,
    };
  },
});
