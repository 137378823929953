
import { defineComponent, ref } from 'vue';
import { useCurrentEnterpriseHook } from '@/store';
import { useStore } from 'vuex';
import List from './List.vue';


export default defineComponent({
  name: 'FinanceBill',
  components: { List },
  setup() {
    useCurrentEnterpriseHook('');
    const store = useStore();
    const { enterprise = {} } = { ...store.state.User };
    const activeKey = ref();
    const tabs = ref<{label: string, value: number}[]>([]);
    if (enterprise.energyTypes.includes(1) || enterprise.energyTypes.includes(2)) {
      tabs.value.push({ label: '加油对账单', value: 1 });
      activeKey.value = 1;
    }
    return {
      activeKey,
      tabs,
    };
  },
});
