
import { defineComponent, reactive, ref } from 'vue';
import moment, { Moment } from 'moment';
import { noEmptyProp, not } from '@/utils/illuminate';
import type { TableColumn } from '@/components/Table';
import { request } from '@/utils/request';
import { createApiUrl } from '@/utils/utils';
import { LeftOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import { useRoute } from 'vue-router';
import { orderStatusEnum, payTypeEnum, orderStatusListEnum } from './dict';

export default defineComponent({
  name: 'FinanceBillDetail',
  components: {
    LeftOutlined,
  },
  setup(prop, ctx) {
    const route = useRoute();
    const filterParams = reactive<{
      orderNo: string | number;
      orderStatus: string | number;
      timeRange: Moment[];
    }>({
      orderNo: '',
      orderStatus: '',
      timeRange: [],
    });
    const tableRef = ref<any>(null);
    const dataSource = ref([]);

    const tableColumns: TableColumn[] = [
      { title: '订单编号', dataIndex: 'orderNo', width: '230px' },
      { title: '订单状态', dataIndex: 'orderStatus', customRender: ({ text }): string => orderStatusEnum[text as keyof typeof orderStatusEnum] },
      { title: '能源类型', dataIndex: 'energyType' },
      { title: '员工姓名', dataIndex: 'userName' },
      { title: '手机号', dataIndex: 'userPhone' },
      { title: '部门', dataIndex: 'accountMainDeptName' },
      { title: '车牌号', dataIndex: 'licensePlateNo' },
      { title: '订单金额', dataIndex: 'totalOrderAmount' },
      { title: '服务费金额', dataIndex: 'serviceFeeAmount' },
      { title: '企业支付金额', dataIndex: 'payAmount' },
      { title: '优惠金额', dataIndex: 'totalDiscountAmount' },
      { title: '企业退款金额', dataIndex: 'refundAmount' },
      { title: '可开票金额', dataIndex: 'totalInvoiceAmount' },
      { title: '支付方式', dataIndex: 'payType', customRender: ({ text }): string => payTypeEnum[text as keyof typeof payTypeEnum] },
      { title: '下单时间', dataIndex: 'orderCreateTime', width: '180px' },
      { title: '支付时间', dataIndex: 'payCompletionTime', width: '180px' },
      { title: '退款时间', dataIndex: 'refundCompletionTime', width: '180px' },
    ];

    const loadTableData = async (parameter: any) => {
      const params = {
        ...not(noEmptyProp(filterParams), ['timeRange']),
        ...parameter,
        billNo: route.query.billNo,
      };

      if (filterParams.timeRange.length) {
        const [start, end] = filterParams.timeRange;
        params.payCompletionStartTime = moment(start).format('YYYY-MM-DD 00:00:00');
        params.payCompletionEndTime = moment(end).format('YYYY-MM-DD 23:59:59');
      }
      try {
        const { data } = await request.post(createApiUrl('/newlinkSass/accountStatement/detailList'), params, { noEnterpriseId: true });
        return data;
      } catch (e:any) {
        message.error(e.message);
      }
    };

    const onRefreshTable = () => {
      tableRef.value.refresh();
    };


    return {
      filterParams,
      tableRef,
      tableColumns,
      loadTableData,
      onRefreshTable,
      dataSource,
      orderStatusEnum,
      orderStatusListEnum,
    };
  },
});
