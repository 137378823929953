import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_enterprise_input = _resolveComponent("app-enterprise-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_app_table = _resolveComponent("app-table")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: _ctx.visible,
    "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.visible) = $event)),
    class: "allCompanyBlanceModal",
    title: "分子公司账户余额",
    width: "700px",
    footer: null
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_a_form_item, { label: "公司" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_enterprise_input, {
              value: _ctx.filterParams.enterpriseId,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterParams.enterpriseId) = $event)),
              style: {"width":"180px"},
              mode: "list",
              export: "id",
              "no-group": "",
              "allow-clear": ""
            }, null, 8, ["value"])
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_app_table, {
        ref: "tableRef",
        size: "default",
        "row-key": "confNo",
        columns: _ctx.tableColumns,
        data: _ctx.loadTableData,
        "page-size": 5,
        "show-size-changer": false
      }, null, 8, ["columns", "data"])
    ]),
    _: 1
  }, 8, ["visible"]))
}