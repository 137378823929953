
import { message } from 'ant-design-vue';
import { defineComponent, reactive, ref, getCurrentInstance, onMounted } from 'vue';
import moment, { Moment } from 'moment';
import { dispatchDownload, noEmptyProp, not } from '@/utils/illuminate';
import type { TableColumn } from '@/components/Table';
import { request } from '@/utils/request';
import { createApiUrl } from '@/utils/utils';

const payTypeMap = ['人工充值', '微信', '支付宝', '银行转账'];

export default defineComponent({
  setup(prop, ctx) {
    const instance = getCurrentInstance()?.proxy;
    const filterParams = reactive<{
      approvalStatus: string | number;
      payType: string | number;
      timeRange: Moment[];
      queryEnterpriseId: number | string,
    }>({
      approvalStatus: '',
      payType: '',
      queryEnterpriseId: '',
      timeRange: [],
    });
    const tableRef = ref<any>(null);
    const dataSource = ref([]);

    const tableColumns: TableColumn[] = [
      { title: '交易流水号', dataIndex: 'serialNo' },
      { title: '交易时间', dataIndex: 'rechargeTime' },
      { title: '入账公司', dataIndex: 'enterpriseName' },
      { title: '充值方式', dataIndex: 'payType', customRender: ({ text, record }) => payTypeMap[record.payType] || '--' },
      { title: '金额(元)', dataIndex: 'rechargeAmount', slots: { customRender: 'rechargeAmount' } },
      { title: '交易状态', dataIndex: 'payStatus', slots: { customRender: 'payStatus' } },
      { title: '操作人', dataIndex: 'submitterName', customRender: ({ record }) => (record.submitterPhone ? `${record.submitterName} - ${record.submitterPhone}` : record.submitterName) },
    ];

    const loadTableData = async (parameter: any) => {
      const params = {
        ...not(noEmptyProp(filterParams), ['timeRange']),
        ...parameter,
      };

      if (filterParams.timeRange.length) {
        params.payStartTime = filterParams.timeRange[0].format('yyyy-MM-DD 00:00:00');
        params.payEndTime = filterParams.timeRange[1].format('yyyy-MM-DD 23:59:59');
      }

      const { data } = await request.get(createApiUrl('/newlinkSass/pay/history'), { params, noEnterpriseId: true });
      return data;
    };

    const onRefreshTable = () => {
      tableRef.value.refresh();
    };

    // = ---------------------------- = 导出数据 = ---------------------------- =
    const confirmVisible = ref(false);
    const exportParams = reactive<{
      timeRange: Moment[];
    }>({
      timeRange: [],
    });

    const onExport = () => {
      Object.assign(exportParams, {
        timeRange: [],
      });
      confirmVisible.value = true;
    };

    let firstSelectTime: Moment | null;
    const isDateDisable = (currentDate: Moment) => {
      if (moment().valueOf() - currentDate.valueOf() < 0) return true;
    };
    const onOpenChange = (value: boolean) => {
      if (!value) firstSelectTime = null;
    };

    const onCalendarChange = (dateRange: [Moment, Moment]) => {
      // eslint-disable-next-line prefer-destructuring
      firstSelectTime = dateRange[0];
    };

    const exporting = ref(false);
    const onConfirmExport = async () => {
      const params: Record<string, any> = {};
      if (!exportParams.timeRange.length) {
        message.warning('请选择时间范围在导出');
        return;
      }
      if (exportParams.timeRange.length) {
        params.payStartTime = exportParams.timeRange[0].format('YYYY-MM-DD');
        params.payEndTime = exportParams.timeRange[1].format('YYYY-MM-DD');
      }
      let res: any;
      try {
        res = await request.get(createApiUrl('/newlinkSass/excel/down/payDetail'), { responseType: 'blob', params: { ...params } });
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      const blob = new Blob([res.data], {
        type: 'application/octet-stream;charset=UTF-8',
        endings: 'transparent',
      });

      const url = URL.createObjectURL(blob);
      const dataTime = moment().format('YYYYMMDDhh:mm:ss');
      dispatchDownload(`充值记录${dataTime}.xlsx`, url);
      // 释放之前创建的URL对象
      window.URL.revokeObjectURL(url);
      confirmVisible.value = false;
    };

    onMounted(() => {
      instance?.$sensors.track('es_saas_recharge_list_page_view', { page_name: '充值/余额管理_充值记录_浏览' });
    });

    return {
      filterParams,
      tableRef,
      tableColumns,
      loadTableData,
      onRefreshTable,
      dataSource,

      isDateDisable,
      onOpenChange,
      onCalendarChange,

      onExport,

      confirmVisible,
      exporting,
      exportParams,
      onConfirmExport,
    };
  },
});
